const S3_URL = 'https://portalv3-front-images-dev.s3.amazonaws.com';
export const LOGO_ASSERTIVA = "".concat(S3_URL, "/images/horizontal-components/assertiva-login/logo-assertiva-roxo.png");
export const LOGO_ASSERTIVA_FULL = "".concat(S3_URL, "/images/assertiva/assertiva-logos_logo-cor-full.png");
export const PURPLE_BACKGROUND_TEXTURE = "".concat(S3_URL, "/images/horizontal-components/assertiva-login/background-image-login.png");

// Background Banner Campaign
export const LOGIN_BANNER_DEFAULT = "".concat(S3_URL, "/images/horizontal-components/assertiva-login/banners_campaign/login_banner_default.png");

// Confirmation screen icons

export const SUCCESS_GREEN_GIF_ICON = "".concat(S3_URL, "/images/success-green-gif-icon.gif");
export const THUMB_ICON = "".concat(S3_URL, "/images/thumb_icon.png");
export const EMPTY_TEMPLATES_MESSAGES = "".concat(S3_URL, "/images/comunik/es-modelos-de-mensagens.svg");
export const EMPTY_SENDERS = "".concat(S3_URL, "/images/comunik/es-usuarios.svg");
export const IMG_PHONE = "".concat(S3_URL, "/images/comunik/preview_message_purple.svg");
export const LISTENING_CHANNEL_ICON = "".concat(S3_URL, "/images/painel/icone-ouvidoria.svg");
export const NOTIFICATIONS_ERROR_ICON = "".concat(S3_URL, "/images/notifications-error-background.svg");

// Products rounded icon
export const BASECERTA_ROUNDED_ICON = "".concat(S3_URL, "/images/base-certa.svg");
export const LOCALIZE_ROUNDED_ICON = "".concat(S3_URL, "/images/localize.svg");
export const SIMPLIFICA_ROUNDED_ICON = "".concat(S3_URL, "/images/icon-simplifica.svg");
export const RECUPERE_ROUNDED_ICON = "".concat(S3_URL, "/images/recupere-icone.svg");
export const CREDITOMIX_ROUNDED_ICON = "".concat(S3_URL, "/images/creditomix-painel-icon.svg");
export const GESTAO_ROUNDED_ICON = "".concat(S3_URL, "/images/gestao-da-conta.svg");
export const SMS_ROUNDED_ICON = "".concat(S3_URL, "/images/sms.svg");
export const API_ICON = "".concat(S3_URL, "/images/api.svg");
export const API_CARD_ICON = "".concat(S3_URL, "/images/produto-api-logo.svg");
export const VEICULOS_ROUNDED_ICON = "".concat(S3_URL, "/images/veiculos-sidebar-icon.svg");
export const GEOTIMIZE_ROUNDED_ICON = "".concat(S3_URL, "/images/geotimize.svg");
export const AUTENTICA_ROUNDED_ICON = "".concat(S3_URL, "/images/autentica.svg");
export const SIGNATURE_ROUNDED_ICON = "".concat(S3_URL, "/images/logo-signatories.svg");
export const WEBPHONE_ROUNDED_ICON = "".concat(S3_URL, "/images/icon-webphone.svg");
export const PERSON_ICON = "".concat(S3_URL, "/images/person_icon.svg");
export const EMPTY_DATA_ICON = "".concat(S3_URL, "/images/vazio.svg");
export const SCORE_CREDITO_ICON = "".concat(S3_URL, "/images/score-credit-icon.svg");
export const SCORE_RECUPERE_ICON = "".concat(S3_URL, "/images/score-recover-icon.svg");
export const CREDITO_ICON = "".concat(S3_URL, "/images/icone-analise-de-credito.svg");
export const DOSSIE_ICON = "".concat(S3_URL, "/images/icons/dossie.svg");
export const DOSSIE_ROUNDED_ICON = "".concat(S3_URL, "/images/dossie-icon.svg");
export const PROTESTO_ICON = "".concat(S3_URL, "/images/icons/icone-protesto.svg");

// Were in the icon folder
export const COMPANY_ICON = "".concat(S3_URL, "/images/assertiva/company.svg");
export const LOCALIZE_ICON = "".concat(S3_URL, "/images/assertiva/localize.svg");
export const LOCALIZE_LEGEND_ICON = "".concat(S3_URL, "/images/assertiva/localizeLegend.svg");
export const PERSON_LOCALIZE_ICON = "".concat(S3_URL, "/images/assertiva/person.svg");
export const PF_ICON = "".concat(S3_URL, "/images/assertiva/pf.svg");
export const PJ_ICON = "".concat(S3_URL, "/images/assertiva/pj.svg");
export const WHATSAPP_MESSAGE = "".concat(S3_URL, "/images/comunik/whatsapp_message.jpg");
export const SUCCESS_DIALOG_ICON = "".concat(S3_URL, "/images/ezgif-1.svg");
export const COPY_TO_CLIPBOARD_ICON = "".concat(S3_URL, "/images/horizontal-components/copyClipboardIcon.svg");
export const COMPUTER_WARNING = "".concat(S3_URL, "/images/horizontal-components/computer_warning.webp");
export const DESKTOP_NOTIFICATIONS_LOGO = "".concat(S3_URL, "/images/assertiva/favicon.png");