function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { applyDefaultEnvsWhenFound, applySpecificEnvsWhenFound } from './applyEnvsWhenFound';
import env from './env';
const test = () => {
  const {
    API_GATEWAY_URL,
    NOTIFIER_API_URL
  } = applyDefaultEnvsWhenFound({
    API_GATEWAY_URL: 'https://parquinho-k8s-tst.assertivasolucoes.com.br',
    NOTIFIER_API_URL: 'https://notifier-tst.assertivasolucoes.com.br'
  });
  return applySpecificEnvsWhenFound(_objectSpread(_objectSpread({}, env()), {}, {
    // api
    API_URL: 'https://api-tst.assertivasolucoes.com.br',
    API_GATEWAY_URL,
    CADASTRO_API_URL: "".concat(API_GATEWAY_URL, "/cadastro-backoffice"),
    CAMPAIGNS_API_URL: "".concat(API_GATEWAY_URL, "/cadastro-backoffice/campaigns"),
    CUSTOMER_API_URL: "".concat(API_GATEWAY_URL, "/backoffice-cliente"),
    COMUNIKA_API_URL: "".concat(API_GATEWAY_URL, "/tst-"),
    RECUPERE_API_URL: "".concat(API_GATEWAY_URL, "/tst-"),
    NOTIFIER_API_URL,
    NOTIFIER_CUSTOMER_API_URL: "".concat(NOTIFIER_API_URL, "/backoffice-cliente"),
    NOTIFIER_CADASTRO_API_URL: "".concat(NOTIFIER_API_URL, "/cadastro-backoffice"),
    REPORT_API_URL: 'https://ws-tst.assertivasolucoes.com.br',
    // apps
    AUTENTICA_URL: 'https://autentica-release.assertivasolucoes.com.br',
    ASSINATURAS_URL: 'https://assinaturas-release.assertivasolucoes.com.br',
    PAINEL_URL: 'https://painel-release.assertivasolucoes.com.br',
    LOCALIZE_URL: 'https://localize-release.assertivasolucoes.com.br',
    CADASTRO_URL: 'https://painel-release.assertivasolucoes.com.br/grupos-e-usuarios/grupos',
    SMS_URL: 'https://sms-release.assertivasolucoes.com.br',
    GEOTIMIZE_URL: 'https://geotimize-release.assertivasolucoes.com.br',
    BASECERTA_URL: 'https://basecerta-release.assertivasolucoes.com.br',
    CREDITOMIX_URL: 'https://creditomix-release.assertivasolucoes.com.br',
    VEICULOS_URL: 'https://veiculos-release.assertivasolucoes.com.br',
    RECUPERE_URL: 'https://recupere-release.assertivasolucoes.com.br',
    WEBPHONE_URL: 'https://webphone-release.assertivasolucoes.com.br',
    CREDITO_URL: 'https://credito-release.assertivasolucoes.com.br',
    DOSSIE_URL: 'https://dossie-release.assertivasolucoes.com.br',
    PROTESTO_LANDING_PAGE_URL: 'https://assertivasolucoes.com.br/lp/protesto-online/'
  }));
};
export default test;