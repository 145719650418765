function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
// FIXME: resolver problemas do eslint após refact.
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import BASE_PROFILES, { BACKOFFICE_BASE_PROFILES } from 'constants/baseProfiles';
import CUSTOM_PROFILES from 'constants/customProfiles';
import PLANS from 'constants/plans';
import { GESTAO_DA_CONTA_ID } from 'constants/productIds';
import find from 'lodash/find';
import FunctionsUtils from 'utils/FunctionUtils';
class User {
  /**
   * Create a current user info
   */
  constructor() {
    var _this = this;
    let {
      user,
      profile,
      company,
      group,
      plan,
      products,
      permissions: _permissions,
      finalAccessTime
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    _defineProperty(this, "id", void 0);
    _defineProperty(this, "email", void 0);
    _defineProperty(this, "name", void 0);
    _defineProperty(this, "userTypeId", void 0);
    _defineProperty(this, "systemId", void 0);
    _defineProperty(this, "permissions", void 0);
    _defineProperty(this, "company", void 0);
    _defineProperty(this, "profile", void 0);
    _defineProperty(this, "group", void 0);
    _defineProperty(this, "plan", void 0);
    _defineProperty(this, "products", void 0);
    _defineProperty(this, "createdAt", void 0);
    _defineProperty(this, "finalAccessTime", void 0);
    // User info getters
    /**
     * Get user ID
     */
    _defineProperty(this, "getId", () => this.id);
    /**
     * Get user email
     */
    _defineProperty(this, "getEmail", () => this.email);
    /**
     * Get user name
     */
    _defineProperty(this, "getName", () => this.name);
    _defineProperty(this, "getFirstName", () => {
      return this.name ? this.name.split(' ').shift() : '';
    });
    _defineProperty(this, "getLastName", () => {
      if (this.getName()) {
        const lastName = this.getName().split(' ').pop();
        return lastName;
      }
      return '';
    });
    _defineProperty(this, "getInitialsName", () => {
      const names = this.name && this.name.trim().split(' ');
      if (names && names.length > 0) {
        const indexLastName = names.length - 1;
        return "".concat(names[0][0]).concat(names[indexLastName][0]).toUpperCase();
      }
      return '';
    });
    _defineProperty(this, "getFirstAndLastName", () => {
      if (this.getName()) {
        const names = this.getName().split(' ');
        const totalNames = names.length - 1;
        return "".concat(names[0], " ").concat(names[totalNames]);
      }
      return '';
    });
    // Profile info getters
    /**
     * Get user profile ID
     */
    _defineProperty(this, "getProfileId", () => this.profile.id);
    /**
     * Get user profile name
     */
    _defineProperty(this, "getProfileName", () => this.profile.name);
    // Product info and permission getters
    /**
     * Get user product list
     */
    _defineProperty(this, "getProducts", () => this.products);
    /**
     * Get user product by ID
     * @param {number} id - Product ID
     */
    _defineProperty(this, "getProductById", id => find(this.products, {
      id
    }));
    /**
     * Check is exist a product by id
     * @param {number} id
     */
    _defineProperty(this, "hasProductById", id => this.getProductById(id) !== undefined);
    _defineProperty(this, "getCreatedAt", () => this.createdAt);
    _defineProperty(this, "hasAPI", () => this.company.api);
    _defineProperty(this, "isCompanyMigration", () => this.company.migration);
    _defineProperty(this, "isCustomProfile", () => this.profile.isCustomProfile);
    _defineProperty(this, "getBaseProfileName", () => {
      var _this$profile$base;
      return this.isCustomProfile() ? (_this$profile$base = this.profile.base) === null || _this$profile$base === void 0 ? void 0 : _this$profile$base.name : this.profile.name;
    });
    _defineProperty(this, "hasPermission", function () {
      const normalizedPermissionKey = [];
      const throwErrorIfNotString = function () {
        for (var _len2 = arguments.length, keys = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          keys[_key2] = arguments[_key2];
        }
        keys.forEach(key => {
          if (!['string'].includes(FunctionsUtils.getTypeOf(key))) {
            throw new Error("[AuthUtils.hasPermission] par\xE2metro(s) inv\xE1lido(s). Apenas strings s\xE3o aceitas, utilizando um dos formatos:\n            - hasPermission(\"A\"),\n            - hasPermission(\"A\",\"B\",\"C\")\n            - hasPermission([ \"A\", \"B\", \"C\" ])");
          }
        });
      };
      for (var _len = arguments.length, permissionKeys = new Array(_len), _key = 0; _key < _len; _key++) {
        permissionKeys[_key] = arguments[_key];
      }
      permissionKeys.every(key => {
        if (Array.isArray(key)) {
          throwErrorIfNotString(...key);
          normalizedPermissionKey.push(...key);
        } else {
          throwErrorIfNotString(key);
          normalizedPermissionKey.push(key);
        }
        return true;
      });
      return _this.permissions.some(permission => normalizedPermissionKey.includes(permission));
    });
    /**
     * Devolve o nível da permissão considerando o ultimo block da flag.
     */
    _defineProperty(this, "getPermissionLevel", permissionKey => {
      if (!permissionKey) {
        return '';
      }
      if (!this.hasPermission(permissionKey)) {
        return '';
      }
      return [...permissionKey.split(':')].pop();
    });
    /**
     *  Devolve true se a flag for de nível EMPRESA
     */
    _defineProperty(this, "hasCompanyLevelPermission", permissionKey => {
      return this.getPermissionLevel(permissionKey) === 'EMPRESA';
    });
    /**
     *  Devolve true se a flag for de nível GRUPO
     */
    _defineProperty(this, "hasGroupLevelPermission", permissionKey => {
      return this.getPermissionLevel(permissionKey) === 'GRUPO';
    });
    /**
     *  Devolve true se a flag for de nível USUARIO
     */
    _defineProperty(this, "hasUserLevelPermission", permissionKey => {
      return this.getPermissionLevel(permissionKey) === 'USUARIO';
    });
    /**
     * Devolve informações sobre a flag ({ level: string, permission: boolean })
     */
    _defineProperty(this, "getInfoPermission", permissionKey => {
      return {
        permission: this.hasPermission(permissionKey),
        level: this.getPermissionLevel(permissionKey)
      };
    });
    /**
     * Verifica se a(s) permissão(ões) passadas possui algum dos três níveis de acesso.
     */
    _defineProperty(this, "hasSomeLevelPermission", permissionKey => {
      const permissions = Array.isArray(permissionKey) ? permissionKey : [permissionKey];
      return permissions.some(permission => this.hasPermission(["".concat(permission, ":EMPRESA"), "".concat(permission, ":GRUPO"), "".concat(permission, ":USUARIO")]));
    });
    /**
     * Verifica se é assertiva usando o REACT_APP_APP_ENV, assim é possivel diferenciar qual o identificador
     */
    _defineProperty(this, "isAssertiva", () => {
      const companyId = process.env.REACT_APP_APP_ENV !== 'production' ? 3 : 1;
      return this.company.id === companyId;
    });
    /**
     * Checks whether the user is allowed to access a resource depending on its profile, i.e. "EMPRESA", "GRUPO" or "USUARIO"
     * @param {object} userInfo - { companyId: 0, groupId: 0, userId: 0 }
     * @param {string} permissionKey - Root Permission Key, like "SMS:EDITAR"
     */
    _defineProperty(this, "hasPermissionProfile", function (permissionKey) {
      let userInfo = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        companyId: undefined,
        groupId: undefined,
        userId: undefined
      };
      if (_this.permissions.includes("".concat(permissionKey, ":EMPRESA"))) {
        return userInfo.companyId === _this.company.id;
      }
      if (_this.permissions.includes("".concat(permissionKey, ":GRUPO"))) {
        return userInfo.groupId === _this.group.id;
      }
      if (_this.permissions.includes("".concat(permissionKey, ":USUARIO"))) {
        return userInfo.userId === _this.id;
      }
      return false;
    });
    // Company info getters
    _defineProperty(this, "getCompany", () => this.company);
    /**
     * Get user company ID
     */
    _defineProperty(this, "getCompanyId", () => this.company.id);
    /**
     * Get user company name
     */
    _defineProperty(this, "getCompanyName", () => this.company.name);
    /**
     * Get user company name
     */
    _defineProperty(this, "getCompanyDocument", () => this.company.document);
    // Group info getters
    /**
     * Get user group ID
     */
    _defineProperty(this, "getGroupId", () => this.group.id);
    /**
     * Get user group name
     */
    _defineProperty(this, "getGroupName", () => this.group.name);
    _defineProperty(this, "getPlan", () => this.plan);
    _defineProperty(this, "isTrial", () => this.plan.planType === PLANS.TRIAL);
    _defineProperty(this, "getMFA", () => this.company.mfa);
    /**
     * is considered blocked due to non-payment, the user who has
     * only the FINANCEIRO:BOLETOENOTAFISCAL:VISUALIZAR permission and
     * only access to GESTAO_DA_CONTA_ID product id (6).
     */
    _defineProperty(this, "isBlockedByNonPayment", () => {
      return this.permissions.length === 1 && this.permissions[0] === 'FINANCEIRO:BOLETOENOTAFISCAL:VISUALIZAR' && this.products.length === 1 && this.products[0].id === GESTAO_DA_CONTA_ID;
    });
    /**
     * is considered blocked when MFA can t validation, the user who has
     * only the ADMINISTRATIVO:PRIVACIDADESESEGURANCA:MFA:VALIDAR:USUARIO
     */
    _defineProperty(this, "isBlockedByMFAValidation", () => {
      return this.permissions.length === 1 && this.permissions[0] === 'ADMINISTRATIVO:PRIVACIDADESESEGURANCA:MFA:VALIDAR:USUARIO' && this.products.length === 0;
    });
    _defineProperty(this, "isPendingByMFAValidation", () => {
      return this.products.length === 1 && this.products[0].id === GESTAO_DA_CONTA_ID && this.permissions.includes('ADMINISTRATIVO:PRIVACIDADESESEGURANCA:VISUALIZAR:USUARIO');
    });
    _defineProperty(this, "isAdminProfile", () => this.getBaseProfileName() === BASE_PROFILES.ADMINISTRATOR.NAME);
    _defineProperty(this, "isBackofficeAdminProfile", () => this.getBaseProfileName() === BACKOFFICE_BASE_PROFILES.ADMINISTRATIVO);
    _defineProperty(this, "isSquadVoidProfile", () => this.getBaseProfileName() === CUSTOM_PROFILES.VOID);
    _defineProperty(this, "isAtendimentoProfile", () => this.getBaseProfileName() === CUSTOM_PROFILES.ATENDIMENTO);
    _defineProperty(this, "isManagerProfile", () => this.getBaseProfileName() === BASE_PROFILES.MANAGER.NAME);
    _defineProperty(this, "isOperatorProfile", () => this.getBaseProfileName() === BASE_PROFILES.OPERATOR.NAME);
    _defineProperty(this, "isFinancialProfile", () => this.getBaseProfileName() === BASE_PROFILES.FINANCIAL.NAME);
    _defineProperty(this, "getFinalAccessTime", () => this.finalAccessTime);
    this.id = undefined;
    this.userTypeId = undefined;
    this.systemId = undefined;
    this.email = '';
    this.name = '';
    this.permissions = [];
    this.products = [];
    this.company = {
      id: undefined,
      name: '',
      tradingName: '',
      document: '',
      segment: {
        id: undefined,
        name: ''
      },
      api: false,
      migration: false,
      mfa: false
    };
    this.profile = {
      id: undefined,
      name: '',
      base: null,
      isCustomProfile: false
    };
    this.group = {
      id: undefined,
      name: ''
    };
    this.plan = {
      id: undefined,
      planType: ''
    };
    this.createdAt = null;
    this.finalAccessTime = '';
    if (user) {
      this.id = user.id;
      this.email = user.email;
      this.name = user.name;
      this.userTypeId = user.userTypeId;
      this.systemId = user.systemId;
      this.createdAt = user.createdAt;
    }
    if (profile && profile.id) {
      this.profile.id = profile.id;
      this.profile.name = profile.name;
      this.profile.isCustomProfile = profile.isCustomProfile;
      this.profile.base = profile.base;
    }
    if (company && company.id) {
      this.company.id = company.id;
      this.company.name = company.name;
      this.company.document = company.document;
      this.company.api = company.api;
      this.company.mfa = company.mfa;
      this.company.migration = company.migration;
      this.company.tradingName = company.tradingName;
      this.company.segment = company.segment;
    }
    if (group && group.id) {
      this.group.id = group.id;
      this.group.name = group.name;
    }
    if (plan && plan.id) {
      this.plan = plan;
    }
    if (products) {
      this.products = products.map(product => product);
    }
    if (_permissions) {
      this.permissions = Object.freeze(_permissions);
    }
    if (finalAccessTime) {
      this.finalAccessTime = finalAccessTime;
    }
    return Object.freeze(this);
  }
  init() {
    this.id = undefined;
    this.userTypeId = undefined;
    this.systemId = undefined;
    this.email = '';
    this.name = '';
    this.createdAt = null;
    this.permissions = [];
    this.products = [];
    this.company = {
      id: undefined,
      name: '',
      tradingName: '',
      document: '',
      segment: {
        id: undefined,
        name: ''
      },
      api: false,
      migration: false,
      mfa: false
    };
    this.profile = {
      id: undefined,
      name: '',
      base: null,
      isCustomProfile: false
    };
    this.group = {
      id: undefined,
      name: ''
    };
    this.finalAccessTime = '';
  }
}
export default User;