import { styled } from '@material-ui/core';
export const StyledMenuIconContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& .NotificationsMenuIcon-animated': {
    width: '50px',
    height: '50px',
    backgroundColor: 'rgb(255 255 255 / 60%)',
    borderRadius: '100px',
    position: 'absolute',
    pointerEvents: 'none'
  }
});
export const StyledMenuIcon = styled('div')({
  '& .notifications-menu-icon': {
    color: '#FFF'
  },
  '& .notifications-menu-badge .MuiBadge-badge': {
    color: '#FFF',
    backgroundColor: '#E82124',
    height: '20px',
    padding: '12px 13px',
    minWidth: '20px',
    fontSize: '12px',
    borderRadius: '7px',
    alignSelf: 'center',
    marginLeft: '12px',
    marginBottom: '-29px',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '-5px',
      width: '0px',
      height: '0px',
      borderStyle: 'solid',
      borderWidth: '0 6px 8px 6px',
      borderColor: 'transparent transparent #E82124 transparent',
      transform: 'rotate(0deg)'
    }
  }
});