export const initialPagination = {
  page: 0,
  rowsPerPage: 5
};
export const initialUsersTableProps = {
  users: [],
  groups: [],
  profiles: []
};
export const initialFilters = {
  name: '',
  email: '',
  userStatus: '',
  mfaStatus: '',
  group: '',
  profile: ''
};
export const FILTERS_LABELS = {
  USUARIO: 'Usuário',
  STATUS: 'Status do usuário',
  GROUP: 'Grupo',
  PROFILE: 'Perfil',
  MFA_STATUS: 'Autenticação por dois fatores'
};
export const FILTERS_KEYS = {
  Nome: 'name',
  'E-mail': 'email',
  Status: 'userStatus',
  Grupo: 'group',
  Perfil: 'profile',
  'Autenticação por dois fatores': 'mfaStatus'
};
export const MENU_OPTIONS = {
  EDIT_USER: 'editUserOption',
  DELETE_USER: 'deleteUserOption',
  DISABLE_USER: 'disableUserOption',
  ENABLE_USER: 'enableUserOption',
  SHOW_USER_PERMISSIONS: 'showUserPermissionOption',
  RESUBMIT: 'Reenviar link',
  SHOW_MFA_SECURITY_QUESTION: 'Pergunta de segurança',
  RESET_MFA: 'Recadastrar autenticação por dois fatores'
};